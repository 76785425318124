import React, { useState } from "react"
import clsx from "clsx"
import useHashTags from "../../hooks/useHashTags"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"

interface HashtagsProps {}

export const Hashtags = ({}: HashtagsProps) => {
    const { t: tCommon } = useTranslation("common")

    const router = useRouter()
    const { locale, query } = router
    const { ht } = query

    const onClickTag = (tag: any) => {
        /*let newTags = []
        if (ht) {
            if (Array.isArray(ht)) {
                newTags = [...ht]
            } else {
                newTags.push(ht)
            }
        }

        if (newTags.indexOf(tag.id) === -1) {
            newTags.push(tag.id)
        }*/

        if (ht && ht === tag.id) {
            delete query.ht

            router.push(
                {
                    pathname: "/search",
                    query: { ...query }
                },
                undefined,
                { locale: locale }
            )
        } else {
            router.push(
                {
                    pathname: "/search",
                    query: { ...query, ht: tag.id }
                },
                undefined,
                { locale: locale }
            )
        }
    }

    const { data: hashtags } = useHashTags()

    const [isShowMoreTags, setIsShowMoreTags] = useState(false)

    return (
        <div className={"mx-auto flex max-w-[967px] flex-wrap items-center gap-[12px] px-4 lg:px-0"}>
            {hashtags &&
                hashtags.map((tag, index) => {
                    return (
                        <div
                            onClick={() => onClickTag(tag)}
                            className={clsx(
                                "b-flex-center hidden h-[21px] w-fit cursor-pointer rounded-[29px] px-[15px] text-[#F7DFFA] hover-active:font-bold lg:flex lg:h-[30px]",
                                tag.colorLevel === 1 && "bg-[rgba(133,26,146,.4)]",
                                tag.colorLevel === 2 && "bg-[rgba(133,26,146,.5)]",
                                tag.colorLevel === 3 && "bg-[rgba(133,26,146,.6)]",
                                tag.colorLevel === 4 && "bg-[rgba(133,26,146,.8)]",
                                tag.colorLevel === 5 && "bg-[rgba(133,26,146,1)]",
                                tag.textLevel === 1 && "text-12",
                                tag.textLevel === 2 && "text-14",
                                tag.textLevel === 3 && "text-16",
                                tag.textLevel === 4 && "text-18",
                                tag.textLevel === 5 && "text-20",
                                index > 10 && !isShowMoreTags && "lg:hidden"
                            )}
                            key={tag.id}
                        >
                            #{tag.name}
                        </div>
                    )
                })}
            {hashtags &&
                hashtags.map((tag, index) => {
                    return (
                        <div
                            onClick={() => onClickTag(tag)}
                            className={clsx(
                                "b-flex-center h-[21px] w-fit cursor-pointer rounded-[29px] px-[15px] text-[#F7DFFA] hover-active:font-bold lg:hidden lg:h-[30px]",
                                tag.colorLevel === 1 && "bg-[rgba(133,26,146,.4)]",
                                tag.colorLevel === 2 && "bg-[rgba(133,26,146,.5)]",
                                tag.colorLevel === 3 && "bg-[rgba(133,26,146,.6)]",
                                tag.colorLevel === 4 && "bg-[rgba(133,26,146,.8)]",
                                tag.colorLevel === 5 && "bg-[rgba(133,26,146,1)]",
                                tag.textLevel === 1 && "text-12",
                                tag.textLevel === 2 && "text-14",
                                tag.textLevel === 3 && "text-16",
                                tag.textLevel === 4 && "text-18",
                                tag.textLevel === 5 && "text-20",
                                index > 7 && !isShowMoreTags && "hidden"
                            )}
                            key={tag.id}
                        >
                            #{tag.name}
                        </div>
                    )
                })}
            {!isShowMoreTags && (
                <div
                    onClick={() => setIsShowMoreTags(true)}
                    className={"cursor-pointer text-12 font-semibold leading-[12px] hover:text-primary"}
                >
                    {tCommon("moreTags")}
                </div>
            )}
        </div>
    )
}
