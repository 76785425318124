import React from "react"

import bbb1 from "../../assets/imgs/home/what-is-bbb.png"

import text from "../../assets/imgs/what-is-bbb/text.png"
import { useTranslation } from "next-i18next"

export const WhatIsBBB = () => {
    const { t: tHome } = useTranslation("home")

    return (
        <div className={"my-16 lg:px-6"}>
            <div className={"flex flex-col justify-center lg:flex-row"}>
                <div className={"c-title text-center lg:hidden"}>{tHome("introduction.title")}</div>
                <div>
                    <img src={bbb1.src} className={"mx-auto mt-2 w-[277px] lg:mt-0 lg:w-full"} />
                </div>
                <div className={"relative m-auto max-w-[544px] px-[26px] lg:ml-[115px] lg:mr-0 lg:px-0"}>
                    <div className={"c-title hidden text-left lg:block"}>{tHome("introduction.title")}</div>
                    <div className={"mt-5 text-base font-normal leading-[19px] text-black"}>
                        <p
                            className="mb-4"
                            dangerouslySetInnerHTML={{ __html: tHome("introduction.description1") }}
                        ></p>
                        <p className="mb-4">{tHome("introduction.description2")}</p>
                        <p>
                            <img src={text.src} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
