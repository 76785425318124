import React, { useState } from "react"
import { GameSortOptions, GameStatusEnum, getGames } from "../../services/games"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import useSWR from "swr"
import useBBBNetwork from "../../hooks/useBBBNetwork"
import { ProgressBar } from "../game/ProgressBar"
import { Share } from "../game-details/Share"
import { DollarSvg, GroupPeopleSvg } from "../../assets/svg"
import { formatNumber } from "../../utils/number"
import RetainQueryLink from "../shared/RetainQueryLink"
import { GameBadge } from "../shared/GameBadge"

export const Market = () => {
    const { t: tCommon } = useTranslation("common")
    const { t: tHome } = useTranslation("home")

    const [filterType, setFilterType] = useState(GameSortOptions.LIQUIDITY)

    const { currentChainId } = useBBBNetwork()

    const fetcher = async () => {
        const response = await getGames(filterType, 1, 6, null, null, null, null, 'BETTING')

        return response.data.data.list
    }

    const { data: games, error } = useSWR(
        currentChainId ? `${filterType}_${currentChainId}` : null,
        fetcher
    )
    const isLoading = !games && !error
    return (
        <>
            <div className="mt-[43px]">
                <div className={"text-center text-24 font-bold leading-[29px] lg:text-28 lg:leading-[34px]"}>
                    {tHome("featuredOnBBB")}
                </div>
                <ul
                    className={clsx(
                        "mx-auto mt-6 grid w-full max-w-[1240px] grid-cols-1 flex-wrap gap-[20px] lg:mt-7 lg:grid-cols-3",
                        isLoading && "min-h-[463px]"
                    )}
                >
                    {games &&
                        games.map((game, index) => {
                            const {
                                id,
                                slug,
                                mainImage,
                                title,
                                hashtags,
                                uniqueHash,
                                volume,
                                playerCount,
                                type: gameType
                            } = game

                            return (
                                <li
                                    key={index}
                                    className={
                                        "relative h-[227px] w-[90%] justify-self-center rounded-[9px] bg-[#EDEBEB] py-[6px] px-[8px] transition duration-500 hover:translate-y-[-10px] hover:border-[0.5px] hover:border-primary lg:h-[242px] lg:w-[400px]"
                                    }
                                >
                                    <div className={"absolute h-full w-full"}>
                                        <RetainQueryLink href={`/game-details/${slug}-${id}`}>
                                            <a className={"block h-full w-full"}>&nbsp;</a>
                                        </RetainQueryLink>
                                    </div>
                                    <div className={"flex"}>
                                        <div className={"h-[84px] w-[84px]"}>
                                            <img
                                                src={mainImage}
                                                alt={`BBB-${slug}`}
                                                className={"h-full w-full rounded-[9px] object-cover"}
                                            />
                                        </div>
                                        <div className={"ml-[13px] flex flex-1 justify-between"}>
                                            <div>
                                                <div className={"flex items-center"}>
                                                    {hashtags.map((tag, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={
                                                                    "b-flex-center mr-[3px] h-[24px] rounded-[25px] bg-[#F7DFFA] px-[12px] text-10 text-primary"
                                                                }
                                                            >
                                                                #{tag}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className={"ellips-2lines mt-[7px]"}>
                                                    <RetainQueryLink href={`/game-details/${slug}-${id}`}>
                                                        <a
                                                            className={
                                                                "relative text-14 font-medium leading-[19px] text-neutral-1-600 hover:text-accent-1"
                                                            }
                                                            title={title}
                                                        >
                                                            {title}
                                                        </a>
                                                    </RetainQueryLink>
                                                </div>
                                            </div>
                                            <div className={"mt-[3px]"}>
                                                <GameBadge gameType={gameType} isGameDetails={false} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"px-2 lg:px-4"}>
                                        <ProgressBar game={game} isHomePage={true} />
                                    </div>
                                    <div className={"mt-[12px] border-t border-[#E6E6E6] px-4 lg:mt-[19px]"}></div>
                                    <div
                                        className={
                                            "mt-[12px] flex items-center justify-between px-4 text-[#808080] lg:mt-[14px]"
                                        }
                                    >
                                        <div className={"flex items-center"}>
                                            <div className={"flex items-center"}>
                                                <div
                                                    className={
                                                        "b-flex-center h-[17px] w-[17px] rounded-full border-[2px] border-[#808080]"
                                                    }
                                                >
                                                    <DollarSvg width={8} height={10} />
                                                </div>
                                                <div className={"ml-[6px] text-12 font-semibold leading-[14px]"}>
                                                    {formatNumber(volume)}
                                                </div>
                                            </div>
                                            <div className={"ml-6 flex items-center"}>
                                                <GroupPeopleSvg width={18} height={15} />
                                                <div className={"ml-[6px] text-12 font-semibold leading-[14px]"}>
                                                    {formatNumber(playerCount)}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Share
                                                gameDetails={game}
                                                pageUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/game-details/${slug}-${id}`}
                                                shortPageUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/s/${uniqueHash}`}
                                                className={"mt-0 mr-0"}
                                                shareClassName={"fill-[#808080]"}
                                                isHideQrCode={true}
                                                isHideStar={true}
                                            />
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                </ul>
                <RetainQueryLink href={`/search`}>
                    <a className="b-flex-center mx-auto mt-[48px] h-[34px] w-[152px] rounded-[9px] border border-primary font-medium text-primary hover:text-accent-1">
                        {tCommon("button.seeAllMarket")}
                    </a>
                </RetainQueryLink>
            </div>
        </>
    )
}
