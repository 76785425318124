import React from "react"
import { GameType } from "../../services/games"
import { useTranslation } from "next-i18next"
import { NarrowUpSvg } from "../../assets/svg"
import { formatNumber } from "../../utils/number"
import clsx from "clsx"

interface ChangeViewProps {
    gameType: GameType
    title: string
    selection: any
    className?: string
    iconClassName?: string
}

export const ChangeView = ({ gameType, title, selection, className, iconClassName }: ChangeViewProps) => {
    const { t: tCommon } = useTranslation("common")

    const { currentOddsText, oddsTrending } = selection
    const isUp = oddsTrending === "UP"
    const isDown = oddsTrending === "DOWN"
    const isNone = oddsTrending === "NONE"

    return (
        <div className={"flex items-center"}>
            <div className={"mr-[9px] text-12"}>{tCommon(title)}</div>
            {gameType === GameType.PREDICTION && (
                <div
                    className={clsx(
                        "b-flex-center h-[20px] w-fit rounded-[5px] px-[10px]",
                        isUp && "bg-[#D3FED0]",
                        isDown && "bg-[#FED0D0]",
                        isNone && "border border-[#5A5A5A]"
                    )}
                >
                    {isUp && <NarrowUpSvg height={10} />}
                    {isDown && <NarrowUpSvg className={clsx(isDown && "rotate-180 fill-[#FF0000]")} height={10} />}
                    <div className={clsx("text-12 font-bold", isUp && "text-[#0FBF21]", isDown && "text-[#FF0000]")}>
                        {formatNumber(currentOddsText)}
                    </div>
                </div>
            )}
        </div>
    )
}
