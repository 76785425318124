import React from "react"
import deposit from "../../assets/imgs/home/deposit.png"
import getRewarded from "../../assets/imgs/home/get_rewarded.png"
import getRefunded from "../../assets/imgs/home/get_refunded.png"
import joinDao from "../../assets/imgs/home/join_dao.png"
import { useTranslation } from "next-i18next"
import { twMerge } from "tailwind-merge"

export const HowItWorks = () => {
    const { t: tHome } = useTranslation("home")

    const works = [
        {
            img: deposit.src,
            className: "w-[44px] max-w-fit lg:w-full lg:mt-[33px] lg:mb-[26px]",
            title: tHome("howItWorks.deposit.title"),
            description: tHome("howItWorks.deposit.description")
        },
        {
            img: getRewarded.src,
            className: "w-[58px] max-w-fit lg:w-full lg:mb-[27px]",
            title: tHome("howItWorks.getRewarded.title"),
            description: tHome("howItWorks.getRewarded.description")
        },
        {
            img: getRefunded.src,
            className: "w-[66px] max-w-fit lg:w-full lg:mt-[20px] lg:mb-[26px]",
            title: tHome("howItWorks.realtimeWithdraw.title"),
            description: tHome("howItWorks.realtimeWithdraw.description")
        },
        {
            img: joinDao.src,
            className: "w-[45px] max-w-fit lg:w-full lg:mt-[26px] lg:mb-[26px]",
            title: tHome("howItWorks.joinDAO.title"),
            description: tHome("howItWorks.joinDAO.description")
        }
    ]
    return (
        <div className="relative bg-[#EFEFEF] pt-[31px] pb-[56px] lg:bg-none">
            <p className="c-title text-center">{tHome("howItWorks.title")}</p>
            <ul className="mt-5 flex flex-col flex-wrap justify-center gap-[37px] lg:mt-9 lg:flex-row">
                {works.map((item, index) => (
                    <li
                        key={index}
                        className="b-flex-center justify-start px-[28px] lg:block lg:h-[279px] lg:w-[272px] lg:rounded-[5px] lg:border lg:border-[#CECECE] lg:bg-[#F8F8F8] lg:px-[35px]"
                    >
                        <div className={"b-flex-center mr-[30px] w-[80px] lg:mr-0 lg:block lg:w-auto"}>
                            <img src={item.img} className={twMerge(item.className)} />
                        </div>
                        <div className="relative z-10 mt-0 max-w-[234px] flex-1 lg:mt-[-10px] lg:px-0">
                            <p className="mb-1 text-14 font-bold leading-[17px] text-black lg:mb-2 lg:text-21 lg:leading-[25px]">
                                {item.title}
                            </p>
                            <p
                                className="text-14 font-normal leading-[17px] text-black"
                                dangerouslySetInnerHTML={{ __html: item.description }}
                            ></p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
