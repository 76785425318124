import useSWR from "swr"
import { getHashTags } from "../services/games"
import { useRouter } from "next/router"

async function fetcher() {
    const response = await getHashTags()

    return response.data.data.list
}

export default function useHashTags(suspense = false) {
    const router = useRouter()

    const result = useSWR(`GET_HASH_TAGS_${router.locale}`, fetcher, {
        suspense
    })

    return {
        data: result.data,
        isLoading: !result.data && !result.error
    }
}
