import React from "react"
import { GameType } from "../../services/games"
import { WillSvg } from "../../assets/svg"
import { twMerge } from "tailwind-merge"
import { ThumbProps } from "./ThumbDown"
import { toTitleCase } from "../../utils/string"

export const ThumbUp = ({ gameType, name, className, iconClassName }: ThumbProps) => {
    return (
        <>
            {gameType === GameType.PREDICTION ? (
                <div
                    className={twMerge(
                        "b-flex-center h-[15px] w-fit rounded-[5px] bg-[#EBEBEB] px-[6px] lg:h-[17px]",
                        className
                    )}
                >
                    <WillSvg className={twMerge("mr-1 h-[10px] w-[12px] lg:h-[12px] lg:w-[14px]", iconClassName)} />
                    <div className={"text-12 text-black"}>{toTitleCase(name)}</div>
                </div>
            ) : (
                <div
                    className={twMerge(
                        "b-flex-center h-[15px] w-[15px] rounded-full bg-[#EBEBEB] lg:h-[26px] lg:w-[26px]",
                        className
                    )}
                >
                    <WillSvg className={twMerge("h-[10px] w-[12px] lg:h-[12px] lg:w-[14px]", iconClassName)} />
                </div>
            )}
        </>
    )
}
