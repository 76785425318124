import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation, Pagination } from "swiper"
import "swiper/css"
import { TriangleSvg } from "../../assets/svg"
import { getHomeSliders } from "../../services/games"

export const Banner = () => {
    const [sliders, setSliders] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const response = await getHomeSliders()

            setSliders([...response.data.data.list])
        }

        fetchData()
    }, [])

    return (
        <div className="relative mt-[40px] overflow-hidden pb-5 lg:pb-0">
            {sliders && sliders.length > 0 && (
                <>
                    <div className="hidden lg:block">
                        <button
                            type="button"
                            className="swiper-team-button-prev b-inset-center group left-[1rem] z-10 flex translate-x-0 rounded-full"
                        >
                            <TriangleSvg className="fill-[#FF0000] group-hover:fill-accent-1" width={34} height={34} />
                        </button>
                    </div>
                    <Swiper
                        slidesPerView={"auto"}
                        loop={true}
                        speed={1000}
                        spaceBetween={14}
                        centeredSlides={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        navigation={{
                            prevEl: ".swiper-team-button-prev",
                            nextEl: ".swiper-team-button-next",
                            disabledClass: "opacity-50"
                        }}
                        pagination={{
                            el: ".swiper-pagination",
                            clickable: true,
                            renderBullet: (index, className) => `<span class="${className}"></span>`
                        }}
                        modules={[Autoplay, Navigation, Pagination]}
                        className="home-banner !overflow-visible"
                    >
                        {sliders.map((slider, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    {slider.targetLink ? (
                                        <a rel={"noreferrer"} href={slider.targetLink} target={"_blank"}>
                                            <img
                                                src={slider.image}
                                                className="h-full w-full rounded-[10px] object-cover hover:opacity-80"
                                            />
                                        </a>
                                    ) : (
                                        <img src={slider.image} className="h-full w-full rounded-[10px] object-cover" />
                                    )}
                                </SwiperSlide>
                            )
                        })}

                        <div className="swiper-pagination absolute bottom-[-25px] z-[9999] flex h-5 w-full items-center justify-center lg:hidden"></div>
                    </Swiper>
                    <div className="hidden lg:block">
                        <button
                            type="button"
                            className="swiper-team-button-next b-inset-center group left-[auto] right-[1rem] z-10 rotate-180"
                        >
                            <TriangleSvg className="fill-[#FF0000] group-hover:fill-accent-1" width={34} height={34} />
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}
