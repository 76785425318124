import React from "react"
import { GameType } from "../../services/games"
import { useTranslation } from "next-i18next"
import { InsuranceSvg, PredictionSvg } from "../../assets/svg"
import { twMerge } from "tailwind-merge"
import clsx from "clsx"

export const GameBadge = ({ gameType, isGameDetails }) => {
    const { t: tCommon } = useTranslation("common")
    const isPrediction = gameType === GameType.PREDICTION
    const isInsurance = gameType === GameType.INSURANCE

    return (
        <>
            <div className={"relative flex items-center"}>
                {isPrediction && (
                    <PredictionSvg
                        width={isGameDetails ? 29 : 23}
                        height={isGameDetails ? 43 : 34}
                        className={"absolute left-[-10px]"}
                    />
                )}
                {isInsurance && (
                    <InsuranceSvg
                        width={isGameDetails ? 29 : 23}
                        height={isGameDetails ? 43 : 34}
                        className={"absolute left-[-10px]"}
                    />
                )}
                <div
                    className={twMerge(
                        clsx(
                            "b-flex-center h-[15px] w-[79px] justify-end rounded-[5px] bg-[#FFD772] pr-[9px] text-10 font-medium",
                            isInsurance && "bg-[#E0C4FF]",
                            isGameDetails && "h-[18px] w-[96px] rounded-[6px] pr-[12px] text-12"
                        )
                    )}
                >
                    {tCommon(isPrediction ? "gameType1.predict" : "gameType1.insurance")}
                </div>
            </div>
        </>
    )
}
