import React from "react"
import { useTranslation } from "next-i18next"
import { twMerge } from "tailwind-merge"
import clsx from "clsx"
import { formatNumber } from "../../utils/number"
import winImg from "../../assets/imgs/icon/win.png"
import { ThumbUp } from "./ThumbUp"
import { ThumbDown } from "./ThumbDown"
import { ChangeView } from "./ChangeView"
import { getTitle1, getTitle2 } from "../../utils/string"
import { GameStatusEnum } from "../../services/games"

export const ProgressBar = (props) => {
    const { t: tCommon } = useTranslation("common")
    const { game, isHomePage, isShowUserData: isShowPlayerData } = props
    const { status, tokenName, playerBet, type: gameType, selections, result } = game

    const { amount, choice } = playerBet || {}

    const selection1 = selections[0]
    const selection2 = selections[1]

    const isEnd = status === GameStatusEnum.RESULT_APPROVED

    return (
        <div className={twMerge(clsx("mx-auto mt-[14px] ml-[19px] mr-[43px]", isHomePage && "ml-0 mr-0"))}>
            <div className={twMerge(clsx("flex items-center justify-between px-9", isHomePage && "px-0"))}>
                <div className={"flex items-center"}>
                    <ChangeView gameType={gameType} title={getTitle1(gameType)} selection={selection1} />
                    {isEnd && result === 1 && isShowPlayerData && (
                        <div
                            className={
                                "relative ml-5 rounded-[5px] border-[0.5px] border-[#F5B826] bg-[#F4B821] pl-[16px] pr-[6px] text-12 font-semibold text-white"
                            }
                        >
                            <img src={winImg.src} className={"absolute top-[-6px] left-[-14px]"} />
                            {tCommon("win")}
                        </div>
                    )}
                </div>
                <div className={twMerge(clsx("hidden items-center lg:flex", isHomePage && "flex"))}>
                    <ChangeView gameType={gameType} title={getTitle2(gameType)} selection={selection2} />
                    {isEnd && result === 2 && isShowPlayerData && (
                        <div
                            className={
                                "relative ml-5 rounded-[5px] border-[0.5px] border-[#F5B826] bg-[#F4B821] pl-[16px] pr-[6px] text-12 font-semibold text-white"
                            }
                        >
                            <img src={winImg.src} className={"absolute top-[-6px] left-[-14px]"} />
                            {tCommon("win")}
                        </div>
                    )}
                </div>
            </div>

            <div
                className={"b-flex-center relative mt-[7px] mb-[8px] h-[18px] w-full py-[1px] lg:mt-[9px] lg:h-[20px]"}
            >
                <div
                    className={
                        "absolute h-full w-full skew-x-[-18deg] rounded-[2px] rounded-b-[2.5px] border-[0.5px] border-[#C0BDBD] lg:skew-x-[-20deg]"
                    }
                ></div>
                <div className={"relative mr-[2px] h-full"} style={{ width: `${selection1.totalBetPercent}%` }}>
                    <div
                        className={
                            "b-progress-bar-purple absolute h-full w-full skew-x-[-18deg] rounded-[2px] rounded-b-[2.5px] lg:skew-x-[-20deg]"
                        }
                    ></div>
                    <div
                        className={
                            "absolute left-[10px] z-10 h-full w-[300px] text-10 font-bold leading-[16px] text-primary lg:leading-[18px]"
                        }
                    >
                        {formatNumber(selection1.totalBet)} {tokenName} - {selection1.totalBetPercent}%
                    </div>
                </div>
                <div className={"relative mr-[2px] h-full flex-1"}>
                    <div
                        className={
                            "b-progress-bar-orange absolute h-full w-full skew-x-[-18deg] rounded-[2px] rounded-b-[2.5px] lg:skew-x-[-20deg]"
                        }
                    ></div>
                    <div
                        className={
                            "absolute right-[10px] z-10 w-[300px] text-right text-10 font-bold leading-[16px] text-[#FF0000] lg:leading-[18px]"
                        }
                    >
                        {formatNumber(selection2.totalBet)} {tokenName} - {selection2.totalBetPercent}%
                    </div>
                </div>
            </div>

            <div className={twMerge(clsx("flex items-center justify-between px-9 text-12", isHomePage && "px-0"))}>
                <div className={"flex items-center"}>
                    <ThumbUp gameType={gameType} name={selection1.name} />
                    {isShowPlayerData && choice === 1 && (
                        <div
                            className={
                                "b-flex-center relative ml-1 h-[22px] rounded-[4px] border-[0.5px] border-black bg-[#F5F6FA] px-2 text-[#05050F]"
                            }
                        >
                            <div
                                className={twMerge(
                                    clsx(
                                        "absolute top-[50%] left-[-4px] h-[6px] w-[6px] translate-y-[-50%] -rotate-45 border-l-[0.5px] border-t-[0.5px] border-black bg-[#F5F6FA]"
                                    )
                                )}
                            ></div>
                            <span>{tCommon("staked")}&nbsp;</span>
                            <span className={"font-bold"}>
                                {formatNumber(amount)} {tokenName}
                            </span>
                        </div>
                    )}
                </div>
                <div className={"flex items-center"}>
                    {isShowPlayerData && choice === 2 && (
                        <div
                            className={
                                "b-flex-center relative mr-1 h-[22px] rounded-[4px] border-[0.5px] border-black bg-[#F5F6FA] px-2 text-[#05050F]"
                            }
                        >
                            <div
                                className={twMerge(
                                    clsx(
                                        "absolute top-[50%] right-[-4px] h-[6px] w-[6px] translate-y-[-50%] rotate-45 border-r-[0.5px] border-t-[0.5px] border-black bg-[#F5F6FA]"
                                    )
                                )}
                            ></div>
                            <span>{tCommon("staked")}&nbsp;</span>
                            <span className={"font-bold"}>
                                {formatNumber(amount)} {tokenName}
                            </span>
                        </div>
                    )}
                    <ThumbDown gameType={gameType} name={selection2.name} />
                </div>
            </div>
        </div>
    )
}
