import React from "react"
import { GameType } from "../../services/games"
import { WontSvg } from "../../assets/svg"
import { twMerge } from "tailwind-merge"
import { toTitleCase } from "../../utils/string"

export interface ThumbProps {
    name: string
    gameType: GameType
    className?: string
    iconClassName?: string
}

export const ThumbDown = ({ gameType, name, className, iconClassName }: ThumbProps) => {
    return (
        <>
            {gameType === GameType.PREDICTION ? (
                <div className={twMerge("b-flex-center h-[17px] w-fit rounded-[5px] bg-[#EBEBEB] px-[6px]", className)}>
                    <WontSvg width={14} height={12} className={twMerge("mr-1", iconClassName)} />
                    <div className={"text-12 text-black"}>{toTitleCase(name)}</div>
                </div>
            ) : (
                <div className={twMerge("b-flex-center h-[26px] w-[26px] rounded-full bg-[#EBEBEB]", className)}>
                    <WontSvg width={14} height={12} className={iconClassName} />
                </div>
            )}
        </>
    )
}
