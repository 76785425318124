import Head from "next/head"
import { HowItWorks } from "../components/home/HowItWorks"
import { Layout } from "../components/shared/Layout"
import React from "react"
import { WhatIsBBB } from "../components/home/WhatIsBBB"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { Hashtags } from "../components/shared/Hashtags"
import { Banner } from "../components/home/Banner"
import { Market } from "../components/home/Market"

export async function getServerSideProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ["common", "home", "game-details"]))
        }
    }
}

function Home() {
    console.log("NODE_ENV", process.env.NODE_ENV)

    return (
        <div>
            <Head>
                <title>BBB</title>
                <meta
                    name="description"
                    content="It is an information markets platform that lets people trade on the world's most highly-debated topics like crypto, sports, politics, etc"
                />
            </Head>
            <Layout>
                <div className={"h-[20px]"}></div>
                <Hashtags />
                <Banner />
                <Market />
                <WhatIsBBB />
                <HowItWorks />
            </Layout>
        </div>
    )
}

export default Home
